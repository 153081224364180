<template>
    <section>
        <section class="d-flex flex-wrap poppins align-center">
            <v-btn text dense @click="$router.push({ name: 'Admin Course Enrollment'})" class="mr-1 pa-0">
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
                BACK
            </v-btn>
            <span class="f14">/</span> <span class="fw600 primary--text f14 ml-2">{{ title  }}</span>
        </section>
        <SearchBar :placeholder="'Search student'" :value.sync="search" @search="getCourseEnrollees()" class="mt-3"/>

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-color="primary"
            active-class="white"
            show-arrows
            >
            <v-tab
                v-for="item in items"
                :key="item"
                class="text-capitalize poppins f14 fw600"
                active-class=" primary--text"
            >
                {{ item }}
                <!-- <v-badge
                    v-if="tab.count > 0"
                    overlap
                    inline
                    bordered
                    :color="tab.count === 0 ? 'transparent' : 'primary'"
                    :content="tab.count"
                    :offset-x="15"
                    style="'text'">
                    {{  item }}
                </v-badge>
                <span v-else>
                    {{  item }}
                </span> -->
            </v-tab>
        </v-tabs>
        <v-data-table
            :loading="loading"
            :headers="tab === 0 ? pendingCourseEnrollment : tab === 1 ? approvedCourseEnrollment : disqualifiedCourseEnrollment"
            :items="enrollees"
            class="text--center custom-border poppins f14 mt-2">
            <template v-slot:item.mode_of_payment="{ item }" >
                <span class="text-uppercase">
                    {{ item.mode_of_payment }}
                </span>
            </template>
            <template v-slot:item.date_enrolled="{ item }" >
                {{ $dateFormat.dayDateTime(item.date_enrolled) }}
            </template>
            <template v-slot:item.date_approved="{ item }" >
                {{ $dateFormat.dayDateTime(item.date_approved) }}
            </template>
            <template v-slot:item.official_receipt="{ item }" >
                <v-btn v-if="item.mode_of_payment === 'online'" outlined color="secondary-1" dense class="pa-2 f12" @click="requestOR(item)" small> Request OR </v-btn>
                <a
                    v-if="item.mode_of_payment === 'offline'"
                    :href="item?.official_receipt?.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View OR
                </a>
            </template>
            <template v-slot:item.check="{ item }">
                <v-btn v-if="item.mode_of_payment === 'online'" outlined color="secondary-1" dense class="pa-2 f12" @click="checkPaymentStatus(item)" small>Check</v-btn>
            </template>
            <template v-slot:item.action="{ item }">
                <v-select
                    outlined
                    :items="actionItems"
                    item-text="text"
                    item-value="value"
                    width="100"
                    class="no-outline-custom-field poppins f14 ma-auto"
                    dense
                    v-model="item.invoice_status"
                    v-on:change="updateStatus(item)"
                    :style="'width: min-content;'"
                    hide-details
                    :disabled="item.mode_of_payment === 'online'"
                >
                    <template slot="selection"  slot-scope="data">
                        <span dense class="secondary-2--text px-2 border" style="background-color: #BDBDBD33">PENDING</span>
                    </template>
                </v-select>
            </template>
            <template v-slot:item.nomination_form="{ item }" >
                <a
                    v-if="item.nomination_form"
                    :href="item.nomination_form.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View
                </a>
                <!-- <v-btn v-if="item.nomination_form" dense color="secondary-1" text class="f12" small @click="selected=item.nomination_form, dialog = true">
                    <v-icon small dense class="mr-1">
                        mdi-eye-outline
                    </v-icon>
                    View
                </v-btn>
                <span v-else>-</span> -->
            </template>
            <template v-slot:item.proof_of_payment="{ item }" >
                <a
                    v-if="item.proof_of_payment"
                    :href="item.proof_of_payment.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View
                </a>
                <!-- <v-btn v-if="item.proof_of_payment" dense color="secondary-1" text class="f12" small @click="selected= item.proof_of_payment, dialog = true">
                    <v-icon small dense class="mr-1">
                        mdi-eye-outline
                    </v-icon>
                    View
                </v-btn>
                <span v-else>-</span> -->
            </template>
        </v-data-table>
        <!-- <NominationPreview :item="selected" :dialog="dialog" @close="dialog = false"/> -->
        <v-dialog
            persistent
            v-model="confirmationDialog"
            max-width="400">
            <v-card>
                <section class="d-flex flex-row">
                    <v-spacer />
                    <v-btn icon @click="confirmationDialog = false, item.invoice_status = 2" class="ml-auto">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </section>
                
                <section class="d-flex align-center flex-column text-center px-5 py-10">
                    <div class="primary--text fw600 f20">
                        Approve user ?
                    </div>
                    <div class="secondary--text roboto f14 mx-auto">
                        This action cannot be undone.
                    </div>
                </section>
                <v-divider></v-divider>
                <section class="secondary--text poppins d-flex flex-row pa-3">
                    <v-btn text @click="() => {
                            confirmationDialog = false, item.invoice_status = 2
                        }">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success poppins" @click="approve">
                        Approve
                    </v-btn>
                </section>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import { api } from '@/services/axios'
import { mapActions, mapState, mapMutations } from 'vuex';
import { pendingCourseEnrollment, approvedCourseEnrollment, disqualifiedCourseEnrollment } from '../../../constants/tblheaders/enrollment';
import NominationPreview from '@/components/admin/enrollee/NominationPreview.vue';

export default {
    components: {
        NominationPreview
    },
    data: () => ({
        tab: 0,
        search: '',
        loading: false,
        pendingCourseEnrollment,
        approvedCourseEnrollment,
        disqualifiedCourseEnrollment,
        confirmationDialog: false,
        items: [ 'PENDING', 'APPROVED', 'DISQUALIFIED'],
        actionItems: [
            { text: 'APPROVED', value: 1 },
            { text: 'PENDING', value: 2 },
            { text: 'DISQUALIFIED', value: 3 },
        ],
        item: null,
        selected: null,
        toApprove: false,
        dialog: false,
        title: ''
    }),
    methods: {
        ...mapActions('admin', ['getCourseEnrolleesAction', 'updateInvoiceStatus', 'lbpStatusCheckAction']),
        ...mapMutations(['alertMutation']),

        approve() {
            this.confirmationDialog = false
            this.item.invoice_status = 2
            this.toApprove = true
            this.updateStatus(this.item)
        },


        getCourseEnrollees(){
            this.loading = true
            this.getCourseEnrolleesAction({course_uuid: this.$route.params.course_uuid, invoice_status: this.tab === 0 ? 2 : this.tab === 1 ? 1 : 3, search: this.search }).then(res => {
                this.title = res.title
            }).finally(() => {
                this.loading = false
            })
        },

        updateStatus(item){
            this.item = item
            if(item.invoice_status === 1) {
                this.confirmationDialog = true
            } else {
                if(this.toApprove) {
                    this.updateInvoiceStatus({invoice_id: item.invoice_id, status: 1}).then(() => {
                        this.loading = true
                        this.getCourseEnrolleesAction({course_uuid: this.$route.params.course_uuid, invoice_status: this.tab === 0 ? 2 : this.tab === 1 ? 1 : 3, search: this.search }).then(res => {
                            this.title = res.title
                        }).finally(() => {
                            this.loading = false
                        })
                    })
                } else {
                    this.updateInvoiceStatus({invoice_id: item.invoice_id, status: item.invoice_status}).then(() => {
                        this.loading = true
                        this.getCourseEnrolleesAction({course_uuid: this.$route.params.course_uuid, invoice_status: this.tab === 0 ? 2 : this.tab === 1 ? 1 : 3, search: this.search }).then(res => {
                            this.title = res.title
                        }).finally(() => {
                            this.loading = false
                        })
                    })
                }
            }
        },

        async requestOR(item) {
            return await new Promise((resolve, reject) => {
                api.post(`/admin/request/oras/official-receipt`, { 
                        course_uuid: this.$route.params.course_uuid, 
                        user_id: item.user_id,
                        email: item.email,
                        reference_id: item.reference_id,
                     }, { responseType: 'blob'} ).then(res => {
                    this.alertMutation({
                        show: true,
                        text: "Requested OR successfully generated!",
                        type: "success"
                    })
                    const reader = new FileReader();
                    reader.onload = function(event) {
                    const csvText = event.target.result;

                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(new Blob([res.data], { type:  'application/pdf' }));
                    downloadLink.download = `Official Receipt.pdf`;
                    downloadLink.click();
                    resolve(res.data)
                    };

                    reader.readAsText(res.data);
                }).catch(err => {
                    if (err.response && err.response.status === 404) {
                        this.alertMutation({
                            show: true,
                            text: 'Official Receipt not available.',
                            type: "error"
                        })
                    } else {
                        this.alertMutation({
                            show: true,
                            text: 'Something went wrong',
                            type: "error"
                        })
                    }
                })
            })
        },

        checkPaymentStatus(item) {
            this.lbpStatusCheckAction({ email: item.email, ref_id: item.reference_id, amount: item.amount}).then( _status => {
                if(_status === 1 & this.tab !== 1) {
                    this.getCourseEnrollees()
                }

                if(_status === 2 & this.tab !== 0) { 
                    this.getCourseEnrollees()
                }
            })
        }
    },
    computed: {
        ...mapState('admin', {
            enrollees: (state) => state.enrollees
        })
    },
    mounted() {
        // 1 approved
        // 2 pending
        // 3 failed
        this.getCourseEnrollees()
    },
    watch: {
        tab(val) {
            this.$router.replace({ query: { type: val , timestamp: Date.now() } });
            this.getCourseEnrollees()
        }
    }
}
</script>
